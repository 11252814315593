<template>
  <div>
    <div class="modal fade order-placed" id="orderplaced" tabindex="-1" aria-labelledby="orderplacedLabel"
      aria-hidden="true" ref="PlaceOrderModal" style="z-index: 9999;">
      <!-- <div class="modal fade order-placed" id="AlertModalPlaced" tabindex="-1" aria-labelledby="AlertModalLabel" aria-hidden="true" ref="AlertModal" style="z-index: 9999;"> -->
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content" v-if="iswallet == '0'">
          <div class="modal-body text-center">
            <img src="@/assets/images/confirmed-icn.svg" />
            <p class="mt-3 mb-1">
              {{ $t('Your order has been placed') }}.
              <br />{{ $t('You can track order status on orders tab') }}.
            </p>
          </div>
          <div class="modal-footer full-btn d-block">
            <a href="javascript:void(0);" data-bs-dismiss="modal">{{ $t('Track Order') }}</a>
          </div>
        </div>
        <div class="modal-content" v-if="iswallet == '1'">
          <div class="modal-body text-center">
            <img src="@/assets/images/confirmed-icn.svg" />
            <p class="mt-3 mb-1">
              {{ $t('Your recharge has been successfully') }}.
            </p>
          </div>
          <div class="modal-footer full-btn d-block">
            <a href="javascript:void(0);" data-bs-dismiss="modal">{{ $t('Ok') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: ['orderId', 'iswallet'],

  mounted() {
    $(this.$refs.PlaceOrderModal).on("hidden.bs.modal", this.oncloseModle);
    window.$("#orderplaced").on('show.bs.modal', function () { });
    window.$("#orderplaced").modal("show");
    window.$(this.$refs.PlaceOrderModal).modal();
    $(this.$refs.PlaceOrderModal).on("hidden.bs.modal", this.oncloseModle);
  },
  methods: {
    ...mapMutations("product", ["clearCartItems"]),
    ...mapMutations("order", ["clearPlaceOrderData"]),

    oncloseModle() {
      if (this.iswallet == '0') {
        this.$store.commit("product/clearCartItems");
        this.$store.commit("order/clearPlaceOrderData");
        localStorage.setItem('orderUploadImages', '');
        this.$router.push({ name: "order-status", params: { order_id: this.base64_encode(this.orderId) } });
      } else {
        this.$router.push({ name: "wallet" });
      }

    }
  }
};
</script>