<template>
  <div class="login-main">
    <nav-bar></nav-bar>
    <section class="box-flex">
      <div class="container review-main text-capitalize position-relative box-flex__wrapper">
        <div class="row">
          <div class="col-md-12 p-0">
            <div class="center-card | d-flex flex-column">
              <div class="center-card-box| p-3 top-sticky border-bottom">
                <div class="resturant-details">
                  <h4 class>{{ $t('Select Payment Method') }}</h4>
                </div>
              </div>
              <div class="center-card-box | p-3">
                <div class="payble-amount">
                  <span class="mb-1 d-inline-block">{{ $t('Payable Amount') }}</span>
                  <h2><span v-html="$auth.setting && $auth.setting.currency" class="currency"></span>{{ (placeOrderData &&
                    placeOrderData.total_amount) ? placeOrderData.total_amount : 0 }}</h2>
                </div>
                <div class="custom-checkbox">
                  <div class="wallt-amount form-group"
                    v-if="$auth.setting && $auth.setting.wallet_status == 'active' && $auth.user && $auth.user.wallet_status == 'active' && $auth.setting.client_wallet_status == 'active' && userWallet > 0">
                    <input type="checkbox" name="wallt" id="wallt" v-model="walletamount" :value="userWallet"
                      class="form-check-inline" @click="checked()">
                    <label for="wallt">{{ $t('Use your') }} <span class="dsply-wlt"><span v-html="$auth.setting.currency"
                          class="currency"></span>{{ userWallet }} {{ $t('wallet balance') }}</span></label>
                  </div>
                </div>
                <div class="mt-3">
                  <form class="boxed payment-box" v-if="isPayCodEnable || isPayOnlineEnable">
                    <div class="d-block position-relative mb-2" v-if="isPayCodEnable && codCheckPayment">
                      <input type="radio" id="cod" name="payusing" value="cod" :checked="true" />
                      <label for="cod">
                        <div class="card-cls">
                          <img src="@/assets/images/card1.svg" />
                        </div>
                        <span>{{ $t('Cash On Delivery ') }}</span>
                      </label>
                    </div>
                    <div v-if="isPayOnlineEnable && onlineCheckPayment">
                      <div class="d-block position-relative mb-2" v-for="(pay, index) in payment_gateway_list"
                        :key="index">
                        <input type="radio" :id="'payonline' + index" name="payusing" :value="pay.gatewayname"
                          :checked="!isPayCodEnable ? index == 0 : false"
                          v-if="onlinePaymentEnableOption.includes(pay.gatewayname)" />
                        <label :for="'payonline' + index" v-if="onlinePaymentEnableOption.includes(pay.gatewayname)">
                          <div class="card-cls">
                            <img src="@/assets/images/card2.svg" />
                          </div>
                          <span>{{ pay.displayname }}</span>
                        </label>
                      </div>
                    </div>
                  </form>
                  <div v-else class="order-offers rest-not-found">
                    <div class="text-center my-2" v-if="loading">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </div>
                </div>
              </div>
              <div class="center-card-box | bottom-sticky mt-auto">
                <div class="full-btn green-btn">
                  <b-button variant="primary" class="nxt-btn" disabled v-if="isDisabled">
                    <b-spinner small type="grow"></b-spinner>
                    {{ $t('Loading') }}...
                  </b-button>
                  <button v-else @click="checkOrder" :disabled='isDisabled'>
                    {{ (isDisabled) ? $t('Placing Order...') : $t('Place Order') }}
                  </button>
                </div>
              </div>
            </div>
            <track-order v-if="isOrderSuccess" :orderId="placeOrderId" :iswallet="iswallet"></track-order>
            <fail-order v-if="isOrderfail"></fail-order>
          </div>
        </div>
      </div>
    </section>
    <!--Alert Modal start-->
    <AlertModal v-if="isAlert" :DisplayAlertData="AlertData" v-on:modalClose="onModalCloseFun"></AlertModal>
    <!--Alert Modal End-->
    <Footer></Footer>
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import FailOrder from '../components/FailOrder.vue';
import { mapGetters, mapActions } from "vuex";
import AlertModal from '../components/AlertModal.vue';
import TrackOrder from '../components/TrackOrder.vue';
import Footer from "../components/Footer.vue";

export default {
  components: { NavBar, FailOrder, AlertModal, TrackOrder, Footer },
  data() {
    return {
      //onlinePaymentEnableOption: ['razorpay', 'paypal', 'stripe','omise','yoco','sslcommerz','peach-web','paytm','payumoney','pesapal','paystack','cashfree'], //Only this payment gateway are will be enable for online payment
      onlinePaymentEnableOption: [], //Only this payment gateway are will be enable for online payment
      isOrderfail: false, //Fail order popup
      isOrderSuccess: false, //Success order popup
      payusing: "", //Customer's selected payment gateway
      payment_gateway_list: [], //Admin payment gateway list
      restaurant_payment_option: "", //Admin payment setting
      payment_option: "", //Store payment setting
      restaurantId: 0, //StoreId
      restaurantData: [], //Store data
      isPayCodEnable: false, //Check cod payment
      isPayOnlineEnable: false, //Check online payment
      is_cod_enable: false, //Check customer cod option
      placeOrderData: {}, //Place order data
      placeOrderId: 0, //Place orderid, it get used when need to redirect to track screen
      isDisabled: false, //Used to enable/disable place order button, also for loading
      loading: true,
      isAlert: false,
      AlertData: {},
      iswallet: 0,
      walletamount: false,
      isdisplayOprions: false,
      codCheckPayment: true,
      onlineCheckPayment: true,
      priceChangeProducts: [],
      priceChangeProductscustomisation: [],
    }
  },

  computed: {
    ...mapGetters("order", ["place_order_data"]), //To get current order data to place    
    ...mapGetters("product", ["cart"]), //To cart items to check
    ...mapGetters("user", ["userWallet"]),
  },

  beforeCreate() {
    let localplaceOrderData = JSON.parse(localStorage.getItem("place_order_data"));
    let localCart = JSON.parse(localStorage.getItem("cart"));
    if (localCart && localCart != '' && localCart != null && localCart.length > 0) {
      if (localplaceOrderData && localplaceOrderData != '' && localplaceOrderData != null && Object.keys(localplaceOrderData).length != 0) {
        this.$store.dispatch("order/placeOrderData", localplaceOrderData);
      }
    } else {
      this.$store.dispatch("order/placeOrderData", {});
    }
  },

  mounted() {
    setTimeout(() => {
      //Check customer login    
      if (this.$auth && this.$auth.user) {
        this.is_cod_enable = (this.$auth.user.is_cod_enable) ? true : false;
      } else {
        this.$router.push({ name: "home" });
        return false;
      }
      //Web pay order status
      let routeQry = this.$route.query;
      if (routeQry.success) {
        this.placeOrderId = routeQry.success;
        this.isOrderSuccess = true;
        // window.$(this.$refs.PlaceOrderModal).modal();
      } else if (routeQry.fail) {
        this.isOrderfail = true;
      }
      this.onlinePaymentEnableOption = this.$auth.setting.payment_gateway_new;
      //Get placing order data
      this.placeOrderData = this.place_order_data;
      if (this.placeOrderData == undefined || this.placeOrderData == null || Object.keys(this.placeOrderData).length == 0 || this.placeOrderData.length == 0) {
        this.$router.push({ name: 'home' });
        return false;
      } else {
        if (this.placeOrderData) {
          if (this.placeOrderData.restaurant_id && this.placeOrderData.restaurant_id > 0) {
            this.restaurantId = this.placeOrderData.restaurant_id;
          }
        }
      }

      if (this.$auth.user.wallet_amount > this.placeOrderData.total_amount) {
        this.isdisplayOprions = false;
        this.walletamount = false;
      } else if (this.$auth.user.wallet_amount > this.placeOrderData.total_amount) {
        this.isdisplayOprions = true;
      }
      //Get admin setting
      if (this.$auth.setting) {
        if (this.$auth.setting && this.$auth.setting.payment_gateway_list.length > 0) {
          //this.payment_gateway_list = this.$auth.setting.payment_gateway_new;
          this.payment_gateway_list = this.$auth.setting.payment_gateway_list;
          this.restaurant_payment_option = this.$auth.setting.restaurant_payment_option;
        } else if (this.$auth.setting && this.$auth.setting.payment_gateway_list.length == '0' && this.$auth.setting.restaurant_payment_option == 'Both') {
          this.restaurant_payment_option = this.$auth.setting.restaurant_payment_option;
        } else if (this.$auth.setting && this.$auth.setting.payment_gateway_list.length == '0' && this.$auth.setting.restaurant_payment_option == 'COD') {
          this.restaurant_payment_option = this.$auth.setting.restaurant_payment_option;
        }
      }
      //Call function to get restaurant details
      this.getRestaurantInfo();
      this.checked();
      this.getWalltet();
    }, 1000);
  },

  methods: {
    ...mapActions("restaurant", ["getRestaurantDetails", "getVendorOnOffStatus", "checkVendorRadius", "checkVendorMenuWithPrice"]), //To get restaurant details
    ...mapActions("order", ["placeOrder"]), //To place an order    
    ...mapActions("user", ["getuserWallet"]),

    getWalltet() {
      this.getuserWallet().then((response) => {
        if (response.code == 200) {
          return true;
        }
      });
    },

    checked() {
      if ($('#wallt').is(':checked')) {

        if (this.$auth.user.wallet_amount >= this.placeOrderData.total_amount) {
          this.codCheckPayment = false;
          this.onlineCheckPayment = false;
          // this.isPayCodEnable = false;
          // this.isPayOnlineEnable = false;
          this.loading = false;
        } else {
          this.codCheckPayment = true;
          this.onlineCheckPayment = true;
          // this.isPayCodEnable = true;
          // this.isPayOnlineEnable = true;
          this.loading = false;
        }
      } else {
        this.codCheckPayment = true;
        this.onlineCheckPayment = true;
        // this.isPayCodEnable = true;
        // this.isPayOnlineEnable = true;
        this.loading = false;
      }
    },

    paymentOption() {
      if (this.isdisplayOprions) {
        this.isdisplayOprions = false;
      } else {
        this.isdisplayOprions = true;
      }
    },

    //Get restaurant details
    getRestaurantInfo() {
      if (this.restaurantId > 0) {
        this.getRestaurantDetails({
          vendor_id: this.$auth.getVendorId(),
          restaurant_id: this.restaurantId,
          is_langauge: this.$store.state.lang.locale,
          latitude: parseFloat(localStorage.getItem("latitude")),
          longitude: parseFloat(localStorage.getItem("longitude"))
        }).then((data) => {
          if (data.code == 200) {
            this.restaurantData = data.Result;
            this.payment_option = this.restaurantData.payment_option;
            if (data.Result.franchisee_id && data.Result.franchisee_id != '') {
              this.placeOrderData.franchisee_id = data.Result.franchisee_id;
            } else {
              this.placeOrderData.franchisee_id = 0;
            }
            this.handlePaymentOptions();
          } else {
            this.$swal({ title: 'Store not found' });
          }
        });
      } else {
        this.$swal({ title: 'Store not found' });
      }
    },

    //Check enable/disable payment options
    handlePaymentOptions() {
      //Check master settings, As per store/vendor/restaurant choice
      if (this.restaurant_payment_option == "Restaurant Select Payment Option") {
        if (this.payment_option == 'Both') {
          this.isPayCodEnable = (this.is_cod_enable) ? true : false;
          this.isPayOnlineEnable = true;
        } else if (this.payment_option == 'PAY ONLINE') {
          this.isPayCodEnable = false;
          this.isPayOnlineEnable = true;
        } else if (this.payment_option == 'COD') {
          this.isPayCodEnable = (this.is_cod_enable) ? true : false;
          this.isPayOnlineEnable = false;
        }
        this.loading = false;
      } else { //Check master settings, As per admin choice
        if (this.restaurant_payment_option == 'Both') {
          this.isPayCodEnable = (this.is_cod_enable) ? true : false;
          this.isPayOnlineEnable = true;
        } else if (this.restaurant_payment_option == 'PAY ONLINE') {
          this.isPayCodEnable = false;
          this.isPayOnlineEnable = true;
        } else if (this.restaurant_payment_option == 'COD') {
          this.isPayCodEnable = (this.is_cod_enable) ? true : false;
          this.isPayOnlineEnable = false;
        }
      }
    },

    onModalCloseFun(value) {
      if (value == 'ok') {
        // if(this.priceChangeProducts.length > 0){
        //   this.priceChangeProducts.forEach(product => {
        //     this.$store.commit("product/updateCart", product);
        //   });
        // }
        // if(this.priceChangeProductscustomisation.length > 0){
        //   this.priceChangeProductscustomisation.forEach(product => {
        //     this.$store.commit("product/updateCuatomizeCart", product);
        //   });
        // }
        localStorage.setItem('updateAlert', true);
        //this.$router.push({ name: "vendor", params: { restaurant_name: this.restaurantData.slug} }).catch(()=>{});
        this.$router.push({ name: "review-cart" }).catch(() => { });
      }
      this.isAlert = false;
    },

    //Check order, update payment method and type to place order
    checkOrder() {

      localStorage.setItem("driverTip", this.driver_tip = 0);

      let dataId = document.querySelector("input[type='radio'][name='payusing']:checked");
      if (dataId) {
        this.payusing = dataId.value;
      }
      if (this.walletamount) {
        if (this.$auth.user.wallet_amount >= this.placeOrderData.total_amount) {
          this.payusing = 'wallet';
          this.placeOrderData.remaining_amount = 0;
          this.placeOrderData.wallet_amount = this.placeOrderData.total_amount; // coupen minus
        } else {
          this.placeOrderData.wallet_amount = this.$auth.user.wallet_amount; // coupen minus
          this.placeOrderData.remaining_amount = parseFloat(this.placeOrderData.total_amount - this.placeOrderData.wallet_amount).toFixed(2);
        }
      }

      this.placeOrderData.payment_method = '';
      this.placeOrderData.payment_type = '';

      if (this.payusing != '') {
        if (this.payusing == 'cod') {
          this.placeOrderData.payment_method = 'Cash On Delivery';
          this.placeOrderData.payment_type = 'Cash On Delivery';
        } else if (this.payusing == 'wallet') {
          this.placeOrderData.payment_method = 'Wallet';
          this.placeOrderData.payment_type = 'wallet';
        } else {
          this.placeOrderData.payment_method = 'Online';
          this.placeOrderData.payment_type = this.payusing;
        }
        this.orderRequest();
      } else {
        this.AlertData = {
          displayMessage: this.$t('Please select any payment option to pay'),
          isSetClass: 'paymnt-modal-cls'
        };
        this.isAlert = true;
        window.$(this.$refs.AlertModal).modal();
      }
    },

    //Place order and redirect if payment is web payment
    orderRequest() {
      this.isDisabled = true;
      this.getRestaurantOnOffStatus();
    },

    getRestaurantOnOffStatus() {
      let date = '';
      let time = '';
      let vm = this;
      if (this.placeOrderData.future_delivery_date && this.placeOrderData.future_delivery_date != '') {
        let datetime = this.placeOrderData.future_delivery_date.split(' ');
        date = datetime[0];
        time = datetime[1];
      }
      let data = {
        is_langauge: this.$store.state.lang.locale,
        delivery_type_time_slots: this.placeOrderData.delivery_type,
        starttime: time,
        restaurant_id: this.restaurantId,
        vendor_id: this.placeOrderData.vendor_id,
        date: date,
      };
      this.getVendorOnOffStatus(data).then((data) => {
        if (data.code == 200) {
          if (data.Result.status == '1' || data.Result.status == 1) {
            this.checkRestaurantRadius();
          } else {
            this.$swal({ title: this.$t('Restaurant is currently off, Please try after some time') });
            //alert(this.$t('Restaurant is currently off, Please try after some time'));
            this.isDisabled = false;
          }
        } else {
          // alert(data.msg);
          this.$swal({ title: data.msg });
          this.isDisabled = false;
        }
      }).catch(function () {
        vm.$swal({ title: this.$t('Something went wrong, Please try again') });
        vm.isDisabled = false;
      });
    },

    checkRestaurantRadius() {
      let vm = this;
      let data = {
        is_langauge: this.$store.state.lang.locale,
        latitude: this.placeOrderData.userPrimaryAddress.latitude,
        longitude: this.placeOrderData.userPrimaryAddress.longitude,
        restaurant_id: this.restaurantId,
        vendor_id: this.placeOrderData.vendor_id,
      };
      this.checkVendorRadius(data).then((data) => {
        if (data.code == 200) {
          this.checkRestaurantMenuWithPrice();
        } else {
          this.$swal({ title: this.$t('Your location is out of reach') });
          //alert(this.$t('Your location is out of reach'));
          this.isDisabled = false;
        }
      }).catch(function () {
        vm.$swal({ title: vm.$t('Something went wrong, Please try again') });
        //alert(this.$t('Something went wrong, Please try again'));
        vm.isDisabled = false;
      });
    },

    checkRestaurantMenuWithPrice() {
      let menu_item_ids = [];
      let menu_item_price = [];
      let customisation_menu_item_ids = [];
      let customisation_menu_item_price = [];
      let menu_item_quantity = [];
      let item_packaging_charge = [];

      this.cart.forEach(item => {
        menu_item_ids.push(item.item_id);
        menu_item_price.push(item.item_price);
        menu_item_quantity.push(item.quantity);
        item_packaging_charge.push(item.packaging_charge);
        if (item.selected_type.length) {
          customisation_menu_item_ids.push(item.selectCustoizeID);
          customisation_menu_item_price.push(item.selectCustoizePrice);
        }
      });
      let postdata = {
        is_langauge: this.$store.state.lang.locale,
        vendor_id: this.placeOrderData.vendor_id,
        restaurant_id: this.restaurantId,
        menu_item: menu_item_ids.join(),
        item_price: menu_item_price.join(),
        customisation_id: customisation_menu_item_ids.join(),
        customisation_price: customisation_menu_item_price.join(),
        item_quantity: menu_item_quantity.join(),
        beverage_ids: '',
        packaging_charge: item_packaging_charge.join()
      }
      this.checkVendorMenuWithPrice(postdata).then((data) => {
        if (data.code == 200) {
          this.placeNewOrder();
        } else if (data.code == 205) {
          this.priceChangeProducts = data.Result;
          this.priceChangeProductscustomisation = data.customisation;

          data.Result.forEach((updateData) => {
            const cartindex = this.cart.findIndex(item => parseInt(item.item_id) === parseInt(updateData.item_id));
            let itemTax = 0;
            if (updateData.quantity == 0) {
              this.cart.splice(cartindex, 1);
            } else {
              if (updateData.item_tax != '0') {
                itemTax = (updateData.price - (updateData.price / (1 + (updateData.item_tax / 100))));
              }
              this.cart[cartindex].item_tax = itemTax;
              this.cart[cartindex].item_price = updateData.price;
              this.cart[cartindex].mrp = updateData.mrp;
              this.cart[cartindex].packaging_charge = parseFloat(updateData.packaging_charge);
              if (updateData.quantity < this.cart[cartindex].quantity) {
                this.cart[cartindex].quantity = updateData.quantity;
                this.cart[cartindex].itemTotalQuantity = updateData.quantity;
              }
            }
          })
          /****Customization****/
          if (data.customisation.length > 0) {
            this.cart.forEach((item, i) => {
              data.customisation.forEach((customis) => {
                const cartcustindex = item.selectCustoizeID.findIndex((itmcust) => parseInt(itmcust) === parseInt(customis.customisation_id));
                this.cart[i].selectCustoizePrice[cartcustindex] = customis.price;
              });
            })
          }
          this.$store.commit("product/customizeCartUpdate", true);
          this.AlertData = {
            displayMessage: this.$t('Your cart is updated because few items are not available or price of item is changed, So please review again and place order.'),
            isSetClass: 'placeorder-modal-cls'
          };
          this.isAlert = true;
          this.isDisabled = false;
          window.$(this.$refs.AlertModal).modal();
        } else {
          //alert(data.msg);
          this.$swal({ title: data.msg });
          this.isDisabled = false;
        }
      });
    },

    placeNewOrder() {

      let dataId = document.querySelector("input[type='radio'][name='payusing']:checked");
      if (dataId) {
        this.payusing = dataId.value;
      }

      let vm = this;
      this.placeOrder(this.placeOrderData).then((data) => {
        if (data.code == 200) {
          if (!['cod', 'wallet'].includes(this.payusing)) {
            if (data.webpayurl) {
              localStorage.setItem('payment_url', data.webpayurl);
              // window.open(data.webpayurl, "_self");
            } else {
              localStorage.setItem('payment_url', data.sslcommerzpayurl);
              // this.$router.push({ name: 'payment-online' });
              // window.open(data.sslcommerzpayurl, "_self");
            }
            this.$router.push({ name: 'payment-online' });
          } else {
            if (data.Item) {
              if (data.Item.orderid && data.Item.orderid > 0) {
                this.placeOrderId = data.Item.orderid;
                this.isOrderSuccess = true;
              }
            }
            this.isOrderSuccess = true;
            window.$(this.$refs.PlaceOrderModal).modal();
          }
        } else if (data.code == 101) {
          this.AlertData = {
            displayMessage: this.$t(data.msg),
            isSetClass: 'placeorder-modal-cls'
          };
          this.isAlert = true;
          this.isDisabled = false;
          window.$(this.$refs.AlertModal).modal();
        } else {
          this.isOrderfail = true;
        }
        this.isDisabled = false;
      }).catch(function (error) {
        console.log(error);
        vm.isDisabled = false;
        vm.$swal({ title: vm.$t('Something went wrong, Please try again') });
      });
    }
  }
};
</script>
<style scoped>
.full-btn button,
.full-btn button:hover {
  background: #27AE60;
  border: none;
}

button.btn.btn-primary.disabled,
.prcc-cls-blr,
.prcc-cls,
.prcc-cls:hover,
.prcc-cls-blr:hover {
  background: #27AE60;
  border: none;
}

.delivery-address {
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 20px 15px;
}
</style>